/* ======== */
/* ======== */
/* ======== */
/* ======== */
.desktop-icon {
  width: 160px !important;
}

.desktop-icon image {
  width: 80px !important;
}
.exlupB {
  display: none !important;
}
.sc-bQCEYZ .exlupB {
  display: none !important;
}
.cfxYFJ {
  display: none !important;
}
.sc-hKFxyN .cfxYFJ {
  display: none !important;
}
/* ======== */
/* ======== */
/* ======== */
